import { webServiceApi, engineServiceApi } from '../service';
export default {
  // 帮助
  getWaterMarkerDocForUser(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getWaterMarkerDocForUser',
      params
    );
  },

  // 获取Pat数量
  getPatCount(params) {
    return engineServiceApi.post('/api/sopt/simplifier/getPatCount', params);
  },

  // 获取Pat列表
  getPatList(params) {
    return engineServiceApi.post('/api/sopt/simplifier/getPatList', params);
  },

  // 发送上传文件接口
  uploadPat(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/uploadPat',
      params,
      config
    );
  },

  // 验证pat是否可上传
  verifypat(params) {
    return engineServiceApi.post('/api/sopt/simplifier/verifypat', params);
  },

  // 删除pat文件接口
  deletePat(params) {
    return engineServiceApi.post('/api/sopt/simplifier/deletePat', params);
  },

  // 获取用户设备型号
  getUserDeviceModel(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getUserDeviceModel',
      params
    );
  },

  // 获取shader信息总数接口（获取总数或为某个tag的总数）
  getShaderCount(params) {
    return engineServiceApi.post('/api/sopt/simplifier/getShaderCount', params);
  },

  // 以某种feature获取shader列表数量（针对id和comment的模糊搜索）
  getShaderCountWithFeature(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getShaderCountWithFeature',
      params
    );
  },

  // 获取shader列表接口
  getPidInList(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getPidInList',
      params,
      config
    );
  },

  // 以某种feature获取shader列表（针对id和tag的搜索）
  getPidInListWithFeature(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getPidInListWithFeature',
      params
    );
  },

  // 以某种feature获取shader列表（针对id和tag的搜索）
  editShader(params) {
    return engineServiceApi.post('/api/sopt/simplifier/editShader', params);
  },

  // 获取站内信列表接口
  messageGets(params) {
    return webServiceApi.post('/index.php/Message/gets', params);
  },

  // 获取单条任务记录接口
  taskGet(params) {
    return webServiceApi.post('/index.php/Task/get', params);
  },

  // 删除用户所有消息接口
  messageDelAll(params) {
    return webServiceApi.post('/index.php/Message/del_all', params);
  },

  // 验证apk文件名接口
  verifyApk(params) {
    return engineServiceApi.post('/api/sopt/simplifier/verifyApk', params);
  },

  // 上传Apk接口
  uploadApk(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/uploadApk',
      params,
      config
    );
  },

  // get请求示例
  getExample(params) {
    return webServiceApi.get('/xxxx/xxx', { params });
  },
};
