<template>
  <div class="rays-tuner-shader">
    <div class="shader-topbar">
      <div class="shader-title-con">
        <router-link to="/mainhome/simplifyhome/pat" class="pat-title"
          >PAT管理</router-link
        >
        <svg-icon icon-class="chevron-right" class="title-icon" />
        <div class="shader-title">{{ patName }}</div>
      </div>
      <div class="shader-top-tools">
        <a-input
          placeholder="可输入序号/备注关键词"
          v-model:value="search"
          class="shader-search"
          @press-enter="pressEhter"
          :disabled="shaderFinish != 2"
        >
          <template #prefix>
            <svg-icon icon-class="search" class="top-icon" />
          </template>
        </a-input>
        <div class="shader-search" style="display: flex; align-items: center">
          <a-input-number
            placeholder="请输入重载率"
            v-model:value="sum_load_rate"
            ref="sumLoadRateRef"
            :min="0"
            :max="100"
            @press-enter="pressEhter"
            :disabled="shaderFinish != 2"
          >
          </a-input-number>
          <span style="margin-left: 10px; color: #aaa">%</span>
        </div>
        <a-select
          v-model:value="deviceModel"
          class="device_type"
          placeholder="选择手机型号"
          @change="deviceTypeChange"
          :options="phoneOptions"
        >
        </a-select>
        <a-select
          ref="select"
          v-model:value="shaderType"
          class="shader-type"
          @change="shaderTypeChange"
          :disabled="shaderFinish != 2"
        >
          <a-select-option value=".*">全部</a-select-option>
          <a-select-option value="1">星标</a-select-option>
        </a-select>
      </div>
      <a-modal
        v-model:visible="editModelShow"
        @ok="handleOk"
        :closable="false"
        class="rays-tuner-shader-edit-modal"
        okText="保存"
        :width="388"
      >
        <a-input v-model:value="shaderTip" placeholder="请输入备注"> </a-input>
      </a-modal>
      <a-modal
        v-model:visible="deviceModelShow"
        @ok="handleDeviceOk"
        @cancel="handleDeviceCancel"
        :closable="false"
        class="rays-tuner-shader-device-modal"
        okText="确定"
        :width="388"
      >
        <div class="device_title">{{ deviceModelTip }}</div>
        <a-select
          v-model:value="deviceModel"
          placeholder="选择手机型号"
          :options="phoneOptions"
        >
        </a-select>
      </a-modal>
    </div>
    <div class="shader-body" id="shader_manager_scroll">
      <div class="shader-card-con" v-if="shaderFinish == 2">
        <div
          class="shader-card"
          v-for="item in shaderlist"
          :key="item.shader_id"
          @click="cardListClick(item)"
          :data-shaderid="item.shader_id"
        >
          <div class="card-img-con">
            <div class="img-star-con" @click.stop="editShader(item, 1)">
              <svg-icon
                icon-class="star"
                class="img-star-icon"
                :class="{ 'img-empty': !item.highlight }"
                v-show="item.tag == 0"
              />
              <svg-icon
                icon-class="star-full"
                class="img-star-icon-full"
                v-show="item.tag == 1"
              />
            </div>
            <!-- <img :src="item.url" v-if="item.highlight" /> -->
            <img
              :src="`${engineurl + imgApi + item.highlight}&pat=${
                currentpat.pat_name
              }&user=${userEmail}&pid=${item.shader_id}`"
              v-if="item.highlight"
            />
            <svg-icon icon-class="image2" class="img-nopic" v-else />
          </div>
          <div class="card-detail">
            <div>
              <div class="card-name">
                <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
                  <template #title>{{ item.shader_id }}</template>
                  Shader_{{ item.shader_id }}
                </a-tooltip>
              </div>
            </div>
            <div class="card-info" v-if="hasRate">
              <span>重载率: {{ handleLoadRate(item.load_rate) }}%</span>
              <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
                <template #title>{{ item.source_size }}</template>
                <!-- 代码长度: {{item.source_size}} -->
                <span class="code-length"
                  >代码长度: {{ item.source_size }}</span
                >
              </a-tooltip>
            </div>
            <div class="card-tip-con">
              <svg-icon icon-class="edit" class="top-icon card-edit" />
              <div class="card-tip">
                <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
                  <template #title>{{ item.comment }}</template>
                  {{ item.comment }}
                </a-tooltip>
              </div>
              <a-dropdown
                class="card-drop-con"
                overlayClassName="rays-tuner-shader-edit-menu"
              >
                <a class="ant-dropdown-link" @click.prevent.stop>
                  <svg-icon icon-class="more2" class="top-icon card-drop" />
                </a>
                <template #overlay>
                  <a-menu @click="handleMenuClick(item)">
                    <a-menu-item :key="item.shader_id"> 编辑备注 </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="pat-card-empty" v-else>
        <div class="empty-con">
          <div class="empty-icon"></div>
          <div class="empty-tip">正在获取Shader列表</div>
          <a-progress
            :percent="75"
            status="active"
            stroke-color="#604bdc"
            :showInfo="false"
            v-show="shaderFinish == 1"
          />
          <a-button
            class="empty-upload"
            @click="initPageCount(1)"
            v-show="shaderFinish == 0"
          >
            刷新
          </a-button>
        </div>
      </div>
    </div>
    <div class="pat-footer">
      <a-pagination
        class="pat-footer-tool"
        size="small"
        show-quick-jumper
        v-model:current="current"
        :total="total"
        :pageSize="pageSize"
        @change="getShaderList"
        v-show="total > 0"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  nextTick,
  computed,
} from 'vue';
import {
  setShader,
  setPatDeviceType,
  setSimplifyHomeInfo,
} from '@/models/tunerStorage';
import store from '@/vuex/store';
import { message } from 'ant-design-vue';
import PerfectScrollbar from 'perfect-scrollbar';
import router from '@/routes';
import api from '@/api/pat-manager';
import { getBaseUrl } from '@/api/service';
const engineurl = getBaseUrl('engine');
const imgApi = '/api/sopt/simplifier/getHighlightByFilename?filename=';
export default defineComponent({
  name: 'Shader',
  components: {},
  data() {
    return {
      engineurl,
      imgApi,
    };
  },
  setup() {
    const sumLoadRateRef = ref(null);
    let shaderBodyDom = null;
    let shaderBodyPs = null;
    let shaderTimer = null;
    const search = ref('');
    const sum_load_rate = ref();
    const shaderType = ref('.*');
    const current = ref(1);
    const pageSize = ref(18);
    const total = ref(0);
    const shaderlist = ref([]);
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = computed(() => store.state.shaderTool.currentPat);
    const currentpat = ref($_pat.value);
    const userEmail = ref($_email);
    const simplifyHome = computed(
      () => store.state.shaderTool.simplifyHome || {}
    );
    const patName = ref($_pat.value.pat_name || '');
    const shaderTip = ref('');
    const editModelShow = ref(false);
    const shaderFinish = ref(0);
    const deviceModelShow = ref(false);
    const deviceModel = ref($_pat.value.deviceType || '');
    const phoneOptions = ref();
    const tipStr = ['初次进入请选择手机型号', '确认切换到该设备型号：'];
    const deviceModelTip = ref(tipStr[0]);

    const handleLoadRate = computed(() => {
      return (loadRate) => {
        return (loadRate * 100).toFixed(2);
      };
    });

    const shaderTypeChange = () => {
      current.value = 1;
      getPageCount();
      getShaderList();
    };

    const deviceTypeChange = () => {
      // deviceModelShow.value = true;
      deviceModelTip.value = tipStr[1];
      initPageCount(1);
    };

    const handleDeviceOk = () => {
      if (deviceModel.value != '') {
        shaderFinish.value = 0;
        deviceModelShow.value = false;
        setPatDeviceType(deviceModel.value);
        initShaderList();
      } else {
        message.error('手机型号不能为空!');
      }
    };

    const handleDeviceCancel = () => {
      deviceModel.value = $_pat.value.deviceType || '';
    };

    const getUserDeviceModel = async () => {
      const params = {
        user: $_email,
        user_id: $_user.uid,
      };
      const res = await api.getUserDeviceModel(params);
      if (!res) return;
      if (res.code == 0) {
        const _res = JSON.parse(res.result_list);
        const _phList = [];
        for (let i = 0; i < _res.length; i++) {
          _phList.push({
            value: _res[i],
            label: _res[i],
          });
        }
        phoneOptions.value = _phList;
      } else {
        message.error('获取用户设备型号失败:' + res.detail);
      }
    };

    const initPageCount = async (query, times) => {
      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
      };
      const res = await api.getShaderCount(params);
      if (!res) return;
      if (res.code == 0) {
        const _list = JSON.parse(
          res.result_list || '[{"count": ' + res.count + '}]'
        );
        if (_list.length > 0) {
          total.value = _list[0].count;
          if (deviceModel.value == '' || times == 1) {
            deviceModel.value = _list[0].device_type || '';
          }
          setPatDeviceType(deviceModel.value || '');
        }
        initShaderList(query);
      } else {
        message.error('获取Shader列表总数失败');
      }
    };
    const getPageCount = async () => {
      if (deviceModel.value == '') {
        message.error('手机型号不能为空！');
        return;
      }
      let params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        device_type: deviceModel.value,
        plain_pattern: search.value || '.*',
        tag_pattern: shaderType.value || '.*',
      };

      if (sum_load_rate.value) {
        params = Object.assign(params, {
          sum_load_rate: sum_load_rate.value / 100,
        });
      }
      const res = await api.getShaderCountWithFeature(params);
      if (!res) return;
      if (res.code == 0) {
        total.value = res.count || 0;
      } else {
        message.error('获取Shader列表总数失败');
      }
    };

    const hasRate = ref(true);
    const initShaderList = async (query) => {
      const _params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        device_type: deviceModel.value || $_pat.value.deviceType,
        offset: 0, //pageSize.value * (current.value - 1),
        count: pageSize.value,
      };
      if (query) {
        _params.query = 1;
      }
      const res = await api.getPidInList(_params, { noMessage: true });
      if (shaderTimer) {
        clearTimeout(shaderTimer);
      }
      if (!res) {
        deviceModel.value = $_pat.value.deviceType || '';
        message.error('获取Shader列表失败！');
        return;
      }
      if (res.code == 0) {
        if (res.result_list) {
          let _list = JSON.parse(res.result_list);
          if ((_list[0] && _list[0].source_size == 0) || !_list[0]) {
            hasRate.value = false;
          } else {
            hasRate.value = true;
          }
          shaderFinish.value = 2;
          shaderlist.value = _list;
          setPatDeviceType(deviceModel.value);
          if (shaderBodyPs && shaderBodyDom) {
            nextTick(() => {
              shaderBodyPs.update();
            });
          }
        } else {
          shaderFinish.value = 1;
          message.success('添加获取shader列表任务成功！');
        }
      } else if (res.code == 6) {
        deviceModelShow.value = true;
      } else if (res.code == 3) {
        shaderFinish.value = 1;
        shaderTimer = setTimeout(() => {
          initShaderList(1);
        }, 30000);
      } else {
        shaderFinish.value = 1;
        message.info('正在获取Shader列表：' + res.detail);
      }
    };

    const getShaderList = async () => {
      if (deviceModel.value == '') {
        return;
      }
      let params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        device_type: deviceModel.value,
        plain_pattern: search.value || '.*',
        tag_pattern: shaderType.value || '.*',
        offset: pageSize.value * (current.value - 1),
        count: pageSize.value,
      };

      if (sum_load_rate.value) {
        params = Object.assign(params, {
          sum_load_rate: sum_load_rate.value / 100,
        });
      }
      const res = await api.getPidInListWithFeature(params);
      if (!res) return;
      if (res.code == 0) {
        let _list = JSON.parse(res.result_list);
        if ((_list[0] && _list[0].source_size == 0) || !_list[0]) {
          hasRate.value = false;
        } else {
          hasRate.value = true;
        }
        shaderlist.value = _list;
        if (shaderBodyPs && shaderBodyDom) {
          nextTick(() => {
            shaderBodyPs.update();
          });
        }
      } else {
        message.error('获取Shader列表失败：' + res.detail);
      }
    };

    const pressEhter = () => {
      // 重载率触发失去焦点，保证数值正确
      if (sumLoadRateRef.value) {
        sumLoadRateRef.value.blur();
      }
      current.value = 1;

      getPageCount();
      getShaderList();
    };

    const cardListClick = (data) => {
      setShader(data);
      router.push({
        path: '/shaderhome',
      });
    };

    const editShader = async (data, type, comment) => {
      if (!data.shader_id && data.shader_id != 0) return;

      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        shader_id: data.shader_id,
        type: type || 0,
      };
      if (type === 0) {
        params.comment = comment;
      } else if (type === 1) {
        params.tag = data.tag == 1 ? 0 : 1;
      }
      const res = await api.editShader(params);
      if (!res) return;
      if (res.code == 0) {
        getPageCount();
        getShaderList();
        message.success('编辑Shader成功！');
      } else {
        message.error('编辑Shader失败：', res.detail);
      }
    };

    let selectShader = null;
    const handleOk = () => {
      editShader(selectShader, 0, shaderTip.value);
      editModelShow.value = false;
    };

    const handleMenuClick = (data) => {
      selectShader = data;
      shaderTip.value = data.comment;
      editModelShow.value = true;
    };

    onMounted(() => {
      // 初始化Shader列表scroll
      shaderBodyDom = document.querySelector('#shader_manager_scroll');
      shaderBodyPs = new PerfectScrollbar(shaderBodyDom);
      setShader({});
      const _simp = JSON.parse(JSON.stringify(simplifyHome.value));
      _simp.menuKey = ['2'];
      setSimplifyHomeInfo(_simp);
    });
    onBeforeUnmount(() => {
      if (shaderBodyPs) {
        shaderBodyPs.destroy();
        shaderBodyPs = null;
      }
      shaderBodyDom = null;
      if (shaderTimer) {
        clearTimeout(shaderTimer);
      }
    });
    getUserDeviceModel();
    initPageCount(1, 1);
    return {
      initPageCount,
      currentpat,
      userEmail,
      sumLoadRateRef,
      search,
      sum_load_rate,
      shaderType,
      current,
      pageSize,
      total,
      patName,
      hasRate,
      shaderlist,
      shaderFinish,
      shaderTip,
      deviceModelTip,
      editModelShow,
      phoneOptions,
      deviceModel,
      deviceModelShow,
      handleDeviceCancel,
      shaderTypeChange,
      deviceTypeChange,
      getShaderList,
      pressEhter,
      handleDeviceOk,
      cardListClick,
      editShader,
      handleOk,
      handleMenuClick,
      handleLoadRate,
    };
  },
});
</script>

<style scoped>
.rays-tuner-shader {
  width: 100%;
  height: 100%;
  padding: 20px 39px 24px 39px;
  background-color: #fff;
}
.shader-topbar {
  width: 100%;
  height: 32px;
}
.shader-title-con {
  float: left;
  width: 35%;
  /* width: calc( 100% - 688px ); */
  height: 32px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
}
.pat-title {
  float: left;
  width: 64px;
  font-size: 16px;
  color: #aaa;
  line-height: 32px;
  cursor: pointer;
}
.title-icon {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-left: 8px;
  color: #aaa;
}
.shader-title {
  float: left;
  margin-left: 8px;
  color: #222;
  font-size: 14px;
  line-height: 32px;
}
.shader-top-tools {
  display: flex;
  float: right;
  max-width: 65%;
  /* width: 688px; */
  height: 32px;
  /* border: 1px solid black; */
}
.top-icon {
  width: 16px;
  height: 16px;
}
.shader-search {
  max-width: 220px;
  height: 32px;
  margin-right: 32px;
  border-radius: 4px;
}
.shader-search:hover {
  border-color: #8877ff;
}
.shader-search:focus {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.shader-search.ant-input-affix-wrapper-focused {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.shader-search .top-icon {
  color: #8c8c8c;
}

.shader-search .ant-input-number {
  width: 150px;
}
.shader-search .ant-input-number:hover {
  border-color: #8877ff;
}
.shader-search .ant-input-number:focus {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.shader-search .ant-input-number.ant-input-number-focused {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}

.device_type {
  width: 184px;
  margin-right: 32px;
}
.shader-type {
  width: 92px;
  height: 32px;
  border-radius: 4px;
  color: #3c3c3c;
}
.device_title {
  margin-bottom: 24px;
  color: #222222;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.shader-body {
  position: relative;
  width: calc(100%);
  height: calc(100% - 128px);
  margin-top: 36px;
  /* border: 1px solid black; */
}
.shader-card-con {
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  margin-top: -40px;
  margin-left: -40px;
}
.shader-card {
  width: 239px;
  /* height: 224px; */
  float: left;
  margin-top: 40px;
  margin-left: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
}
.pat-card-empty {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}
.empty-con {
  position: absolute;
  left: calc(50% - 90px);
  top: calc(50% - 80px);
  width: 160px;
  height: 182px;
}
.empty-icon {
  width: 79px;
  height: 79px;
  margin-left: 40px;
  background: url('~@/assets/Inbox.png') no-repeat;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.empty-tip {
  margin-top: 8px;
  color: #666666;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: CENTER;
}
.empty-upload {
  width: 160px;
  height: 48px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 500;
  background: #604bdc;
  border-color: #604bdc;
  border-radius: 4px;
}
.card-img-con {
  position: relative;
  width: 100%;
  height: 158px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  background: #222;
  /* background: url('~@/assets/logo.png') no-repeat center; */
  /* background-size: 128px, 128px; */
}
.card-img-con img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.img-nopic {
  width: 32px;
  height: 32px;
  margin-top: 64px;
  color: #3c3c3c;
}
.img-star-con {
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: left;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 40px;
  background: rgba(0, 0, 0, 0.64);
}
.img-star-icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 8px;
  color: #fff;
}
.img-empty {
  opacity: 0.6;
}
.img-star-icon-full {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 8px;
  color: #ffb800;
}
.card-detail {
  width: 100%;
  /* height: 64px; */
  padding: 8px 8px 8px 6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: LEFT;
  background-color: #fff;
}
.card-name {
  width: 100%;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  color: #000;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-info {
  display: flex;
  justify-content: space-between;
  color: #aaa;
}
.code-length {
  text-align: right;
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-tip-con {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #666;
  line-height: 20px;
}
.card-edit {
  float: left;
  margin-top: 2px;
}
.card-tip {
  float: left;
  width: 190px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-drop-con {
  float: right;
}
.card-drop {
  margin-top: 3px;
  color: #d8d8d8;
}
.card-del {
  margin-top: -5px;
  vertical-align: middle;
}
.pat-footer {
  width: 100%;
  height: 32px;
  padding-top: 3px;
  margin-top: 28px;
}
.pat-footer-tool {
  float: right;
}
</style>
<style>
.rays-tuner-shader
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #8877ff;
}
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgb(136 119 255 / 20%);
}
.rays-tuner-shader-edit-menu > .ant-dropdown-menu {
  width: 88px;
}
.rays-tuner-shader-edit-menu .ant-dropdown-menu-item {
  color: #aaa;
}
.rays-tuner-shader-edit-menu .ant-dropdown-menu-item:hover {
  color: #3c3c3c;
}
.rays-tuner-shader-edit-modal {
  height: 149px;
}
.rays-tuner-shader-edit-modal .ant-modal-footer {
  border-top: 0;
}
.rays-tuner-shader-device-modal .ant-select {
  width: 320px;
}
.rays-tuner-shader-device-modal .ant-modal-footer {
  border-top: 0;
}
</style>
